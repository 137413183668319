.html{
  background: white;
  box-sizing: border-box;
}

.floot{
  position: absolute;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  float: left;
  opacity: 0.75;
  background: #e9ecef;
  color: #282c34;
  text-align:center;
  margin: auto;
  padding:0 0;
  border: 0px;
}
.floot:hover{
  direction: rtl;
  transform:scale(1.1);
}
