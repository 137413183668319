@import url(https://fonts.googleapis.com/css?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.html{
  background: white;
  box-sizing: border-box;
}

.floot{
  position: absolute;
  height: 65px;
  width: 65px;
  border-radius: 50%;
  float: left;
  opacity: 0.75;
  background: #e9ecef;
  color: #282c34;
  text-align:center;
  margin: auto;
  padding:0 0;
  border: 0px;
}
.floot:hover{
  direction: rtl;
  -webkit-transform:scale(1.1);
          transform:scale(1.1);
}

li,a,button{
    text-decoration: none; 
    color:grey;
}
.nav{
    background:white;
    color: black;}
.dark{
    background:black;
    color: white;
}

.header{
    display: flex;
    justify-content: space-around;
    align-content: center;
    flex-wrap: wrap;
    margin: auto;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    padding-top: 0.25em;
    margin-bottom: 2%;
}

.header >li{
list-style: none;
text-decoration: none;
margin-top: 1.5em;
}
.header >li>a:hover{
    
    text-decoration: none;
    color: black;
    border-bottom: 0.5px solid green;
    
}
.header>li:hover{
    -webkit-transform:scale(1.22);
            transform:scale(1.22);
}
.naver{
    height: 100px;
    margin-top: 12px;
}

.logo{
    font-size: 50px;
    color: rgb(131, 94, 94);
}
@-webkit-keyframes logRO{
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@keyframes logRO{
    0%{
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    100%{
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.logo:hover .fabi,logo{
-webkit-animation: logRO 5s linear infinite;
        animation: logRO 5s linear infinite;
-webkit-transform: scale(1.2);
        transform: scale(1.2);
color: black;
}

@media screen and (max-width:1000px){
.header{
    display: flex;
    justify-content: space-around;
    align-content: center;

}
.header>li{
    width: 100%;
    
    text-align: center;
}

}

.footer{
    height: 10px;
    width: 100%;
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    margin-bottom: 0.4rem;
    
}
.footer>li{
    font-style: normal;
    list-style: none;
    font-size: 1rem;
}
.fa-linkedin{
    color: black;
}
.fa-twitter{
    color:black;
}

.fa-instagram{
    color: black;
}

.fa-github{
    color: black;
}
.fa-linkedin:hover{
    color: red;
}
.fa-twitter:hover{
    color:lightskyblue
}

.fa-instagram:hover{
    color: blue;
}

.fa-github:hover{
    color: black;
}
.fa-medium{
    color:black;
}
p{
text-align: center;
color: black;
size: 40px;    
}
@media screen and (max-width:1000px){
    .footer{
        display: flex;
        flex-direction: column;
    }
    .fa-youtube{
        color: red;
    }
    .fa-twitter{
        color:lightskyblue
    }
    
    .fa-instagram{
        color: blue;
    }
    
    .fa-github{
        color: black;
    }  
}

@media screen and (max-width:600px){
    .footer{
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        height: 20vh;
        align-items: center;
        text-align: center;
        justify-items: left;
    }
    .footer .fa{
        text-align: left;
    }
}

  
    
/* .jb-is{
    
} */
.exte{

margin: 15px 10px;
color:black;
size: auto;
text-transform: lowercase;
font-weight: 100;
-webkit-transition-duration: 2s;
        transition-duration: 2s;



}
.exte:hover{
    -webkit-transform: scale(1.20);
            transform: scale(1.20);
    border-radius: 0%;
    position: relative;
    -webkit-transition-duration: 0.75s;
            transition-duration: 0.75s;
    background-color: #000000de;
    color:rgb(255, 255, 255);
    margin-right: 100px;
    margin-bottom: 100px;
    margin-top: 100px;
    margin-left: 100px;
    opacity: 1.5;
}
.imge{
    
    
background-color: rgba(98, 189, 98, 0.116);
margin: 1px 10px;
background: url("https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80");

}
.hovr{
    opacity: 0;
    text-decoration: none;
    border: 0;
    position: absolute;
    margin: auto;
}
.imge:hover .hovr{
opacity: 1;
background: #00ffee;
color: rgba(190, 189, 114, 0.424);
text-align: center;
margin: auto;
position: absolute;
}
.imge:hover{
    position: relative;
   
}
.imge::after {
    content: "";
    background: url("https://images.unsplash.com/photo-1534972195531-d756b9bfa9f2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80");
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }
.imge:hover img{
    opacity:0.5;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}
@-webkit-keyframes ladsh{
    0%{
        -webkit-transform: rotate(0deg) scale(1);
                transform: rotate(0deg) scale(1);
        margin-top: 10%;
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: pink ;
        border-bottom-color: greenyellow;
    }
    20%{
        -webkit-transform: rotate(100deg) scale(1.2);
                transform: rotate(100deg) scale(1.2);
        margin-top: 10%;
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: rgb(209, 93, 112) ;
        border-bottom-color: rgb(167, 196, 125);
    }

    60%{

        -webkit-transform: rotate(200deg) scale(1.375);

                transform: rotate(200deg) scale(1.375);
        margin-top: 10%;
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: rgb(148, 13, 36) ;
        border-bottom-color: rgb(167, 172, 160);
    }
    100%{
        -webkit-transform: rotate(360deg) scale(1.6);
                transform: rotate(360deg) scale(1.6);
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: rgb(29, 18, 184) ;
        border-bottom-color: rgb(118, 190, 10);
    }
}
@keyframes ladsh{
    0%{
        -webkit-transform: rotate(0deg) scale(1);
                transform: rotate(0deg) scale(1);
        margin-top: 10%;
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: pink ;
        border-bottom-color: greenyellow;
    }
    20%{
        -webkit-transform: rotate(100deg) scale(1.2);
                transform: rotate(100deg) scale(1.2);
        margin-top: 10%;
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: rgb(209, 93, 112) ;
        border-bottom-color: rgb(167, 196, 125);
    }

    60%{

        -webkit-transform: rotate(200deg) scale(1.375);

                transform: rotate(200deg) scale(1.375);
        margin-top: 10%;
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: rgb(148, 13, 36) ;
        border-bottom-color: rgb(167, 172, 160);
    }
    100%{
        -webkit-transform: rotate(360deg) scale(1.6);
                transform: rotate(360deg) scale(1.6);
        margin-top: 10%;
        margin-bottom:10% ;
        border-top-color: rgb(29, 18, 184) ;
        border-bottom-color: rgb(118, 190, 10);
    }
}
.load{
margin: auto;
margin-top: 10%;
width: 120px;
height: 120px;
border: 16px solid rgb(218, 92, 92);
border-top-color: pink ;
border-bottom-color: greenyellow;
-webkit-animation: ladsh 5s linear infinite;
        animation: ladsh 5s linear infinite;
border-radius: 50%;

}
.load:hover{
    -webkit-animation-play-state: paused;
            animation-play-state: paused;
}
.contact{
    text-align: left;
}

.title{
    margin: auto;
    font-size: 80px ;
    
}

.title hr {
    border: 0;
    height: 1px;
    background: #333;
    background-image: -webkit-gradient(linear, left top, right top, from(#ccc), color-stop(#333), to(#ccc));
    background-image: linear-gradient(to right, #ccc, #333, #ccc);
}
/* .jumbotron{
   
} */
.black{
    background: black;
    color: white;
    margin-top: 1em;
}


