.footer{
    height: 10px;
    width: 100%;
    margin-top: 0.2rem;
    display: flex;
    justify-content: space-evenly;
    align-content: center;
    margin-bottom: 0.4rem;
    
}
.footer>li{
    font-style: normal;
    list-style: none;
    font-size: 1rem;
}
.fa-linkedin{
    color: black;
}
.fa-twitter{
    color:black;
}

.fa-instagram{
    color: black;
}

.fa-github{
    color: black;
}
.fa-linkedin:hover{
    color: red;
}
.fa-twitter:hover{
    color:lightskyblue
}

.fa-instagram:hover{
    color: blue;
}

.fa-github:hover{
    color: black;
}
.fa-medium{
    color:black;
}
p{
text-align: center;
color: black;
size: 40px;    
}
@media screen and (max-width:1000px){
    .footer{
        display: flex;
        flex-direction: column;
    }
    .fa-youtube{
        color: red;
    }
    .fa-twitter{
        color:lightskyblue
    }
    
    .fa-instagram{
        color: blue;
    }
    
    .fa-github{
        color: black;
    }  
}

@media screen and (max-width:600px){
    .footer{
        display: flex;
        flex-direction: column;
        font-size: 1rem;
        height: 20vh;
        align-items: center;
        text-align: center;
        justify-items: left;
    }
    .footer .fa{
        text-align: left;
    }
}